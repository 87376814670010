import $ from 'jquery';

export function toast(type, message) {

  let toast = $('<div>').addClass('toast toast-' + type)
                        .attr('role', 'alert')
                        .attr('aria-live', 'assertive')
                        .attr('aria-atomic', 'true');

  let button = $('<button>').attr('type', 'button')
                            .attr('data-dismiss', 'toast')
                            .attr('aria-label', 'Close')
                            .addClass('mr-2 mb-1 close');
  button.html('<span aria-hidden="true">&times;</span>');

  let toast_body = $('<div>').addClass('toast-body').html(message);

  toast.append('<span class="mr-auto"></span>');
  toast.append(button);
  toast.append(toast_body);

  $('.toast').remove();
  $('.toast-container').append(toast);
  $('.toast').toast({ delay: 3500 });
  $('.toast').toast('show');
}
