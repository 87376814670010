import $ from 'jquery';

const form = () => $('#account_versions_form');

function accountPicker() {
  form()
    .find('.accountpicker')
    .on('select2:select', e => {
      $.ajax({
        method: 'GET',
        url: `/auditing/${e.params.data.id}/versions`,
        success: function () {
          toast('info', 'Account changes loaded');
        },
        error: function () {
          toast('warning', 'Error loading account changes');
        }
      });
    });
}

export function init() {
  accountPicker();
}

$(init);