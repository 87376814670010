import $ from 'jquery';
import { ajax } from '@rails/ujs';
import qs from 'qs';

import { connectDatatable } from './filters';

$(() => {
  connectDatatable({
    rootSelector: '.vehicle-filters',
    datatableSelector: '.datatable_vehicles',
    builderAttrs: {
      defaultField: 'year',
    },
  });

  $(document).on('change', '#vehicle_status', function(){
    $('.status-fields').addClass('d-none');
    $('.' + $(this).val() + '-fields').removeClass('d-none');
  });
});
