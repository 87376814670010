import $ from 'jquery';
import Cookies from 'js-cookie';

$(() => {
  $('#navbarNav [title]').tooltip();

  if($('.dropdown-item.active').length){
    $('.nav-link.dropdown-toggle').addClass('active');
  }

  $('#dev_site').on('change', function(){
    Cookies.set('selected_dev_site', $(this).val());
    location.reload();
  });
});
