import $ from 'jquery';
import 'pickadate-webpack/lib/picker'
import 'pickadate-webpack/lib/picker.time'

function initialize_timepicker() {
  return this.each(function() {
    $(this)
      .pickatime({
        container: 'body',
        format: 'hh:i A',
        interval: 10,
        editable: true
      }).on('mousedown click focus', function(e){
        $(e.currentTarget).pickatime('open')
        e.stopPropagation();
        e.preventDefault();
      });
  });
}

$.fn.extend({ initialize_timepicker });

$(() => {
  $('.timepicker').initialize_timepicker();
});
