import $ from 'jquery';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import momentPlugin from '@fullcalendar/moment';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Calendar } from '@fullcalendar/core';
import { fire } from '@rails/ujs';
import moment from 'moment'

function update_meeting_time(meeting) {
  $.ajax({
    url: '/meetings/' + encodeURIComponent(meeting.id) + '/time',
    type: 'PATCH',
    dataType: 'json',
    data: { start: meeting.start, end: meeting.end },
  }).done(function() {
    if (!$('#fullcalendar-meeting-form').hasClass('d-none')) {
      var form = document.getElementById('fullcalendar-edit-meeting');
      form.setAttribute(
        'action',
        '/meetings/' + encodeURIComponent(meeting.id) + '/edit'
      );
      fire(form, 'submit');
    }
  });
}

export function refresh_calendar(){
  $('#calendar').data('fullcalendar').refetchEvents();
}

$(() => {

  $(document).on('change', '#meeting_start_date', function(){
    if($('#meeting_start_time').val() == ''){
      $('#meeting_start_time').val(moment().format('hh:mm A'));
      $('#meeting_end_date').val($('#meeting_start_date').val());
      $('#meeting_end_time').val(moment().add(1, 'hour').format('hh:mm A'));
    }
  });

  $(document).on('change', '#meeting_start_time', function(){
    $('#meeting_end_time').val(moment($('#meeting_start_time').val(), 'hh:mm A').add(1, 'hour').format('hh:mm A'));
  });

  var calendarEl = document.getElementById('calendar');

  if (calendarEl !== null) {

    var calendar = new Calendar(calendarEl, {
      plugins: [
        bootstrapPlugin,
        dayGridPlugin,
        interactionPlugin,
        listPlugin,
        momentPlugin,
        momentTimezonePlugin,
        timeGridPlugin,
      ],

      editable: true,
      lazyFetching: false,
      nowIndicator: true,
      selectMirror: true,
      selectable: true,
      themeSystem: 'bootstrap',

      header: {
        center: 'dayGridMonth,timeGridWeek,listWeek',
        right: 'today addMeeting prev,next',
      },

      customButtons: {
        addMeeting: {
          click: function() {
            var form = document.getElementById('fullcalendar-new-meeting');
            fire(form, 'submit');
          },
          bootstrapFontAwesome: 'fa-plus',
        },
      },

      eventSources: [
        {
          url: '/meetings/exchange-calendar-events',
          id: 'exchange',
          color: 'grey',
          failure: function() {
            alert('there was an error while fetching events!');
          },
        },
        {
          url: '/meetings/customer-meeting-events',
          id: 'customer',
          color: 'blue',
          failure: function() {
            alert('there was an error while fetching events!');
          },
        },
      ],

      eventClick: function(info) {
        $('.fc-event.bg-warning').removeClass('bg-warning');
        $(info.el).addClass('bg-warning');
        var form = document.getElementById('fullcalendar-edit-meeting');
        form.setAttribute(
          'action',
          '/meetings/' + encodeURIComponent(info.event.id) + '/edit'
        );
        fire(form, 'submit');
      },

      eventDrop: function(info) {
        update_meeting_time(info.event);
      },

      eventResize: function(info) {
        update_meeting_time(info.event);
      },

      dateClick: function() {
        $('.fc-event.bg-warning').removeClass('bg-warning');
        $('#meeting-form').addClass('d-none');
        $('.meeting-instructions').removeClass('d-none');
      },
    });

    $(calendarEl).data('fullcalendar', calendar);

    calendar.render();
  }
});
