import m from 'mithril';

const FiltersDropdown = {
  view({ attrs }) {
    const globalFilters = attrs.filters.filter(f => f.global);
    const userFilters = attrs.filters.filter(f => !f.global);

    return m('.dropdown.mr-2', [
      m(
        'button.btn.btn-sm.btn-secondary.dropdown-toggle[type=button]',
        { 'data-toggle': 'dropdown' },
        [m('i.fa.fa-filter.mr-2'), 'Filters']
      ),

      m('.dropdown-menu', [
        globalFilters.length > 0 && [
          m('h6.dropdown-header', 'Global'),
          globalFilters.map(filter =>
            m(
              'button.dropdown-item[type=button]',
              { key: filter.id, onclick: () => attrs.setFilter(filter) },
              filter.name
            )
          ),

          m('.dropdown-divider'),
        ],

        userFilters.length > 0 && [
          m('h6.dropdown-header', 'My filters'),
          userFilters.map(filter =>
            m(
              'button.dropdown-item[type=button]',
              { key: filter.id, onclick: () => attrs.setFilter(filter) },
              filter.name
            )
          ),

          m('.dropdown-divider'),
        ],

        m('a.dropdown-item', { href: attrs.url }, 'Manage filters\u2026'),
      ]),
    ]);
  },
};

const NewFilterButton = {
  view({ attrs }) {
    return m(
      'button.btn.btn-sm.btn-outline-success.mr-auto[type=button]',
      { onclick: attrs.newFilter },
      [m('i.fa.fa-plus-circle.mr-2'), 'New filter']
    );
  },
};

const Status = {
  view({ attrs }) {
    if (attrs.status === 'loading') {
      return m('.spinner-border.spinner-border-sm.text-secondary.mr-3');
    }

    if (attrs.status === 'error') {
      return m(
        '.text-danger.mr-3',
        m('i.fa.fa-exclamation-triangle.mr-2'),
        'Error!'
      );
    }

    if (attrs.status === 'saved') {
      return m('.text-success.mr-3', m('i.fa.fa-check.mr-2'), 'Saved!');
    }
  },
};

function SaveFilterForm({ attrs }) {
  let name = '';

  function submit(willReplace = false) {
    name = name.trim();
    if (name === '') {
      return;
    }
    if (
      willReplace &&
      !confirm(`Are you sure you want to replace \u201c${name}\u201d?`)
    ) {
      return;
    }
    if (attrs.saveFilter != null) {
      attrs.saveFilter(name);
    }
    name = '';
  }

  return {
    view({ attrs }) {
      const willReplace =
        attrs.filters
          .filter(f => !f.global)
          .map(f => f.name)
          .indexOf(name) !== -1;

      return m('.input-group.mr-2', [
        m('input.form-control.form-control-sm[type=text]', {
          placeholder: 'Name',
          value: name,
          oninput: e => (name = e.target.value),
        }),
        m('.input-group-append', [
          m(
            'button.btn.btn-sm.btn-outline-primary[type=button]',
            {
              disabled: name.trim() === '',
              onclick: () => submit(willReplace),
            },
            [
              m('i.fa.fa-save.mr-2'),
              !willReplace ? 'Save filter' : 'Replace filter',
            ]
          ),
        ]),
      ]);
    },
  };
}

const ClearFilterButton = {
  view({ attrs }) {
    return m(
      'button.btn.btn-sm.btn-outline-secondary[type=button]',
      { onclick: attrs.clearFilter },
      [m('i.fa.fa-times-circle.mr-2'), 'Clear filter']
    );
  },
};

const Header = {
  view({ attrs }) {
    return m('.btn-toolbar.align-items-center.p-2', [
      m(FiltersDropdown, {
        url: attrs.url,
        filters: attrs.filters,
        setFilter: attrs.setFilter,
      }),

      m(NewFilterButton, { newFilter: attrs.newFilter }),

      m(Status, { status: attrs.status }),

      attrs.filter != null && [
        m(SaveFilterForm, {
          filters: attrs.filters,
          saveFilter: attrs.saveFilter,
        }),

        m(ClearFilterButton, { clearFilter: attrs.clearFilter }),
      ],
    ]);
  },
};

export default Header;
