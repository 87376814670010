import $ from 'jquery';
import 'select2';

$(function() {
  $('.select2-field').each(function(i, element) {
    var $select_el = $(element);

    var placeholder =
      $select_el.attr('data-placeholder') !== ''
        ? $select_el.attr('data-placeholder')
        : '';

    if ($select_el[0].hasAttribute('data-ajax-url')) {
      $select_el.select2({
        ajax: {
          url: $select_el.attr('data-ajax-url'),
          delay: 500,
        },
        minimumInputLength: 3,
        theme: 'bootstrap4',
        allowClear: true,
        placeholder: placeholder || '', // https://github.com/select2/select2/issues/3497#issuecomment-124122158
      });
    } else {
      $select_el.select2({
        theme: 'bootstrap4',
        allowClear: true,
        placeholder: placeholder || '', // https://github.com/select2/select2/issues/3497#issuecomment-124122158
      });
    }
  });
});
