import $ from 'jquery';
import 'select2';

export function initialize_customer_picker() {
  return this.each(function() {
    $(this)
      .prop('readonly', true)
      .select2({
        theme: 'bootstrap4',
        minimumInputLength: 3,
        allowClear: true,
        placeholder: 'Type to search customers',
        ajax: {
          url: '/accounts/customer-search',
          dataType: 'json',
        },
      });
  });
}

$.fn.extend({ initialize_customer_picker });

$(() => {
  $('.customerpicker').initialize_customer_picker();
});
