import $ from 'jquery';
import { ajax } from '@rails/ujs';
import qs from 'qs';

// https://stackoverflow.com/a/60286175/1867759
const toFormData = (f => f(f))(h => f => f(x => h(h)(f)(x)))(f => fd => pk => d => {
  if (d instanceof Object) {
    Object.keys(d).forEach(k => {
      const v = d[k]
      if (pk) k = `${pk}[${k}]`
      if (v instanceof Object && !(v instanceof Date) && !(v instanceof File)) {
        return f(fd)(k)(v)
      } else {
        fd.append(k, v)
      }
    })
  }
  return fd
})(new FormData())()

function start_report_generation(){
  const table = $(".datatable_accounts").find('table');
  const options = $(".datatable_accounts").data('options');

  const instance = $(table).DataTable();

  const url_params = instance.ajax.url().split("?")[1]

  ajax({
    type: 'POST',
    url: $(".contacts-report").attr("data-url") + "?" + url_params,
    data: toFormData(instance.ajax.params()),
    dataType: 'script',
  });
}

$(() => {
  $(document).on("click", ".contacts-report", function(e){
    start_report_generation();
  });
});
