import $ from 'jquery'

$(function () {
  // Delete Teritory Button
  $('.delete-zone-button').on('click', function(){
    if(!confirm('Delete this Zone? This is really not recommended.')) return false;

    // Ajax Delete
    var zone_id = $('#zone_id').val();
    $.ajax({
      url: '/admin/zones/' + zone_id,
      type: 'DELETE'
    })
    .done(function() {
      window.location.href = '/admin/zones';
    });
  });
});
