import $ from 'jquery';
import L from 'leaflet';
import { fire } from '@rails/ujs';

import iconUrl from '../../images/red-marker.png';
import iconRetinaUrl from '../../images/red-marker-2x.png';
import { updateMapLinks } from '../addresses';

$.fn.accountMap = function() {
  if (this.length === 0) {
    return;
  }

  if ($(this).data('map') == null) {
    var center = L.latLng($(this).data('center'));
    var popup = $(this).data('popup');

    var map = L.map(this.get(0), {
      center: center,
      zoom: 13,
      scrollWheelZoom: false,
      tap: false
    });

    map.on('popupopen', function() {
      updateMapLinks();
    });

    // https://docs.mapbox.com/api/maps/#static-tiles
    var template =
      'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}';

    var streets = L.tileLayer(template, {
      id: 'mapbox/streets-v11',
      accessToken: window.MAPBOX_ACCESS_TOKEN,
      maxZoom: 18,
    }).addTo(map);

    var satellite = L.tileLayer(template, {
      id: 'mapbox/satellite-streets-v11',
      accessToken: window.MAPBOX_ACCESS_TOKEN,
      maxZoom: 18,
    });

    L.control.layers({ Streets: streets, Satellite: satellite }).addTo(map);

    var icon = L.icon(
      $.extend({}, L.Icon.Default.prototype.options, {
        iconUrl,
        iconRetinaUrl,
        shadowUrl: '/assets/leaflet/dist/images/marker-shadow.png',
      })
    );

    L.marker(center, { icon: icon })
      .bindPopup(popup)
      .addTo(map);

    $(this).data('map', map);
  }

  return $(this).data('map');
};

$(function() {
  $('#account-map').accountMap();

  $('#account-map-show-nearby').on('change', function() {
    var form = $(this).parents('form');
    $('#nearby_loading-spinner').show();
    fire(form.get(0), 'submit');
  });

  // Add event listener for radius dropdown change
  $('#radius-dropdown').on('change', function() {
    var form = $(this).parents('form');
    $('#nearby_loading-spinner').show();
    fire(form.get(0), 'submit');
  });
});
