import $ from 'jquery';

import { connectDatatable } from './filters';

$(() => {
  connectDatatable({
    rootSelector: '.allotment-filters',
    datatableSelector: '.datatable_allotments',
    builderAttrs: {
      defaultField: 'book_id',
    },
  });
});
