import m from 'mithril';
import $ from 'jquery';

const OPERATORS = [
  { value: 'is_any_of', label: 'Is any of' },
  { value: 'is_not_any_of', label: 'Is not any of' },
  { value: 'is_empty', label: 'Is empty' },
  { value: 'is_not_empty', label: 'Is not empty' },
];

const DropdownWidget = {
  oncreate({ attrs, dom }) {
    $(dom)
      .find('.dropdown')
      .select2({ theme: 'bootstrap4', allowClear: true, placeholder: '' })
      .val(attrs.condition.value)
      .trigger('change')
      .on('select2:select select2:clear', e => {
        attrs.condition.value = $(e.target)
          .select2('data')
          .map(data => data.id);
        m.redraw();
      });
  },

  view({ attrs }) {
    const showDropdown =
      attrs.condition.operator !== 'is_empty' &&
      attrs.condition.operator !== 'is_not_empty';
    const dropdownOptions = attrs.sources[attrs.condition.options.source];

    return m('.d-flex', [
      m(
        'select.custom-select.custom-select-sm.w-50.mr-2',
        { onchange: e => (attrs.condition.operator = e.target.value) },
        OPERATORS.map(({ value, label }) =>
          m(
            'option',
            { value, selected: value === attrs.condition.operator },
            label
          )
        )
      ),

      m('.w-50', [
        m('.select2-sm', { class: showDropdown ? 'd-block' : 'd-none' }, [
          m('select.dropdown', { multiple: true }, [
            dropdownOptions.map(({ value, text }) =>
              m('option', { value }, text)
            ),
          ]),
        ]),
      ]),
    ]);
  },
};

export default DropdownWidget;
