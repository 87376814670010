import $ from 'jquery';

function update_customer_characteristic(value) {
  var uni_id = $('#customerUni').val();
  var data = {};
  data['customer_characteristic'] = value;

  $.ajax({
    method: 'PATCH',
    url: `/account_details/${uni_id}/update_by_uni`,
    data: data,
    success: function() {
      toast('info', 'All Accounts Updated');
    },
    error: function() {
      toast('warning', 'Error Updating Accounts');
    }
  });
}

function update_customer_type(value) {
  var account_id = $('#hidden_account_id').val();
  var data = {};
  data['customer_type'] = value;

  $.ajax({
    method: 'PATCH',
    url: `/account_details/${account_id}/update`,
    data: data,
    success: function() {
      toast('info', 'Account Updated');
    },
    error: function() {
      toast('warning', 'Error Updating Account');
    }
  });
}

$(document).on('change', '#customer_types_select', function() {
  update_customer_type($(this).find(":selected").attr('data-value'));
});

$(document).on('change', '#customer_characteristics_select', function() {
  update_customer_characteristic($(this).find(":selected").attr('data-value'));
}); 
