import $ from 'jquery';
import { fire, ajax } from '@rails/ujs';
import AddressHelper from './address_helper';
import qs from 'qs';

import { connectDatatable } from './filters';

$(() => {
  connectDatatable({
    rootSelector: '.allocation-account-filters',
    datatableSelector: '.datatable_allocation_accounts',
    builderAttrs: {
      defaultField: 'name',
    },
  });

  connectDatatable({
    rootSelector: '.allocation-item-filters',
    datatableSelector: '.datatable_allocation_items',
    builderAttrs: {
      defaultField: 'territory',
    },
  });

  const allocation_address_loader = new AddressHelper({
    form_fields: {
      'name': 'allocation_item_ship_to_line_1',
      'street': 'allocation_item_ship_to_line_2',
      'street-2': 'allocation_item_ship_to_line_3',
      'street-3': 'allocation_item_ship_to_line_4',
      'city': 'allocation_item_ship_to_city',
      'state': 'allocation_item_ship_to_state',
      'zip': 'allocation_item_ship_to_zip_code',
      'country': 'allocation_item_country_code'
    },
    load_addresses_button: 'allocation-addresses',
    load_button: 'load-address'
  });

  if($('.allocation-book-list').length){
    if($('.allocation-book-selection').length == 1 && !$('.allocation-book-selection').hasClass('active')){
      fire($('.allocation-book-selection')[0], 'click');
    }
  }

  $(document).on('select2:select', '.allocation-account.accountpicker', e => {
    $.getJSON(`/accounts/${e.params.data.id}`, data => {

      allocation_address_loader.set_account(e.params.data.id);

      if(parseFloat($('#allocation_item_quantity').val()) == 0) {
        $('#allocation_item_quantity').val(1);
      }

      $('#allocation_item_ship_to_line_1').val(data.primary_address['name']);
      $('#allocation_item_ship_to_line_2').val(data.primary_address['street']);
      $('#allocation_item_ship_to_line_3').val(data.primary_address['street_2']);
      $('#allocation_item_ship_to_line_4').val(data.primary_address['street_3']);
      $('#allocation_item_ship_to_city').val(data.primary_address['city']);
      $('#allocation_item_ship_to_state').val(data.primary_address['state']);
      $('#allocation_item_ship_to_zip_code').val(data.primary_address['zip']);
      $('#allocation_item_country_code').val(data.primary_address['country']);
    });
  });

  $(document).on('click', '.allocation-category-header', function(){
    var category = $(this).attr('data-category');
    $(this).parents('table').find('.category-item-' + category).toggleClass('d-none');
    $(this).find('.toggle-icon').toggleClass('d-none')
  });

  $(document).on('change', '.allotment-brand-check', function(){
    $('.' + $(this).val()).toggle($(this).is(':checked'));
  });

  $(document).on('change', '.sync-address-checkbox', function(e){
    var allotment_item_id = $(this).attr('data-id');
    var sync_address = $(this).is(':checked') ? "true" : "false";
    var expanded_account = $(this).attr('data-expanded-account')

    if($(this).is(':checked') && !confirm('This will copy this address to all other book orders for this customer. Continue?')){
      e.preventDefault();
      return false;
    }

    ajax({
      type: 'POST',
      url: `/allocation-items/${allotment_item_id}/set-address-sync`,
      data: qs.stringify({ sync_address, expanded_account }),
      dataType: 'script',
    });

  });
});

$(document).on('click', '.add-to-allocation', function(){
  const text = $(this).data('text')
  const id = $(this).data('id')
  
  if ($('#book_ids').find("option[value='" + id + "']:selected").length) {
    // do nothing
  } else { 
    const newOption = new Option(text, id, false, true)
    $('#book_ids').append(newOption).trigger('change')
  }
});

$(document).on('keyup', '#allocation-summary-territory-search', function(){
  var result = $("#territory-summary-" + $(this).val());

  if(result.length > 0){
    $('.allocation-summary').hide();
    result.show();
  } else {
    $('.allocation-summary').show();
  }
});

$(document).on('click', '.no-account-alert', function(){
  alert($(this).attr('data-alert'));
})
