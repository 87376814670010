import $ from 'jquery';

$(() => {
  const combinedBrandSales = $('.combined-brand-sales');

  if (combinedBrandSales.length === 0) {
    return;
  }

  combinedBrandSales.on('click', '.toggle-option', function(e) {
    e.preventDefault();

    combinedBrandSales.find('.toggle-option').removeClass('active');
    $(this).addClass('active');

    const salesType = $(this).data('sales-type');
    combinedBrandSales.find('.calendar-row, .fiscal-row, .last-year-row').addClass('d-none');
    combinedBrandSales.find(`.${salesType}-row`).removeClass('d-none');
  });
});
