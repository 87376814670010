import $ from 'jquery';
import { ajax } from '@rails/ujs';
import qs from 'qs';

import { connectDatatable } from './filters';

$(() => {
  connectDatatable({
    rootSelector: '.invoice-filters',
    datatableSelector: '.datatable_invoices',
    builderAttrs: {
      defaultField: 'invoiced_at',
    },
  });
});
