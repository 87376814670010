import $ from 'jquery';

$(function () {
  // Convenience method to auto-select all the territories in a given zone.
  $('.assign-to-zone-button').on('click', function(){
    var zone_id = $('.zone-selection').val();
    if(!zone_id) return false;
    var $error_element = $(this).parents('.form-group').find('.custom-form-error');
    $('.zone-selection').val('');

    $.get('/admin/zones/' + zone_id + '/territories', function(data){
      if(!data.length){
        $error_element.show();
        setTimeout(function(){ $error_element.fadeOut('slow'); }, 2500)
        return false;
      }

      var current_selections = $('#user_territories').val();
      $('#user_territories').val(data.concat(current_selections));
      $('#user_territories').trigger('change');
    });
  });

  // Delete User Button
  $('.delete-user-button').on('click', function(){
    if(!confirm('Delete this user? This is really not recommended.')) return false;

    // Ajax Delete

  });
});
