import m from 'mithril';
import $ from 'jquery';
import 'pickadate-webpack/lib/picker'
import 'pickadate-webpack/lib/picker.date'

const OPERATORS = [
  { value: 'on', label: 'On' },
  { value: 'before', label: 'Before' },
  { value: 'on_or_before', label: 'On or before' },
  { value: 'after', label: 'After' },
  { value: 'on_or_after', label: 'On or after' },
];

const DateWidget = {
  oncreate({ attrs, dom }) {
    $(dom)
      .find('.datepicker')
      .attr('value', attrs.condition.value)
      .prop('readonly', true)
      .pickadate({
        container: 'body',
        format: 'mm/dd/yyyy',
        selectYears: true,
        selectMonths: true,
        onSet: function(context) {
          attrs.condition.value = this.$node.val();
          m.redraw();
        }
      }).on('mousedown click focus', function(e){
        e.stopPropagation();
        e.preventDefault();
      });
  },

  view({ attrs }) {
    return m('.d-flex', [
      m(
        'select.custom-select.custom-select-sm.w-50.mr-2',
        { onchange: e => (attrs.condition.operator = e.target.value) },
        OPERATORS.map(({ value, label }) =>
          m(
            'option',
            { value, selected: value === attrs.condition.operator },
            label
          )
        )
      ),

      m('.w-50', [
        m('input.datepicker.form-control.form-control-sm[type=text]'),
      ]),
    ]);
  },
};

export default DateWidget;
