import $ from 'jquery';
import { ajax } from '@rails/ujs';
import qs from 'qs';

$(() => {
  $(document).on('click', '.remove-form-book', function(){
    $(this).parents('tr').remove();
  });

  $(document).on('click', '.create-form-book', function(){
    let collection_id = $(this).parents('tr').find('.book-collection-id').val();
    let book_id = $(this).parents('tr').find('select').val();
    let book_group = $(this).parents('tr').find('.book-group').val();

    ajax({
      type: 'POST',
      url: `/book_collections/${collection_id}/form-book`,
      data: qs.stringify({ book_id, book_group }),
      dataType: 'script',
    });
  });

  $(document).on('click', '.update-form-book', function(){
    let collection_id = $(this).parents('tr').find('.book-collection-id').val();
    let book_id = $(this).parents('tr').attr('data-book-id');
    let book_group = $(this).parents('tr').find('.book-group').val();

    ajax({
      type: 'PATCH',
      url: `/book_collections/${collection_id}/form-book`,
      data: qs.stringify({ book_id, book_group }),
      dataType: 'script',
    });
  });

});
