import $ from 'jquery';

export default class LocalStorageHelper {
    constructor(options) {
        window.addEventListener('DOMContentLoaded', (event) => {
            this.options = options;
            this.setupModule();
        });
    }

    checkExpiredStorage(){
        let keys = Object.keys(localStorage);
        let i = keys.length;
        const now = new Date();
        
        while ( i-- ) {
            const value = localStorage.getItem(keys[i]);
            if (value.includes("expirationDate")) {
                const data = JSON.parse(value);
                if (now > Date.parse(data["expirationDate"])) {
                    localStorage.removeItem(keys[i]);
                }
            }
        }
        
    }

    getAccount() {
        const customerUni = document.getElementById('customerUni').value;
        return customerUni;
    }

    startInterval(name) {
        this.interval = setInterval(() => {
            this.saveForm(this.options.data, name);
        }, 1000);
    }

    stopInterval() {
        clearInterval(this.interval);
    }

    storageContains(name) {
        return localStorage.getItem(name) !== null;
    }

    addClear(name) {
        if (document.querySelector('.btn-success') !== null){
            document.querySelector('.btn-success').addEventListener('click', () => {
                localStorage.removeItem(name);
                this.stopInterval();
            });
        }
    }

    setupModule() {
        if (this.onPage(this.options.pageCheck)) {
            this.checkDrawer(this.options.callback, this.getAccount());
        } else {
            this.checkForm(this.options.callback, this.options.formName);
        }
    }

    onPage(page) {
        return window.location.href.includes(page);
    }

    checkForm(callback, name) {
        $(() => {
            this.addClear(name);
            if (this.storageContains(name) && this.checkIfSaved()) {
                if (this.storageContains(name)) {
                    $(`#${this.options.modalName}`).modal('toggle');

                    if(document.getElementById(this.options.loadButton)){
                      document.getElementById(this.options.loadButton).addEventListener('click', () => {
                          this.populateForm(name);
                          callback();
                          this.startInterval(name);
                      });
                    }

                    if(document.getElementById(this.options.clearButton)){
                      document.getElementById(this.options.clearButton).addEventListener('click', () => {
                          localStorage.removeItem(name);
                          this.startInterval(name);
                      });
                    }

                }

            } else if (this.checkIfSaved() && this.onPage(this.options.formPage)){
                this.startInterval(name);
            }
        });
    }

    tableEmpty() {
        return document.querySelectorAll(`#${this.options.tableName} tr`).length > 0;
    }

    drawerFormPresent() {
      return document.querySelectorAll('.drawer form').length > 0
    }

    checkDrawer(callback, name) {
        let drawerOpen = false;
        this.checkExpiredStorage();
        this.interval = setInterval(() => {
            if (document.querySelector('.drawer').classList.contains('open')) {
                if (drawerOpen === false && this.drawerFormPresent()) {
                    if (this.storageContains(name) && !this.tableEmpty()) {
                        this.populateForm(name);
                        callback();
                    }
                    this.addClear(name);
                    drawerOpen = true;
                }
                this.saveForm(this.options.data, name);
            } else {
                drawerOpen = false;
            }
        }, 1000);
    }

    saveColumn(selector) {
        const column = document.querySelectorAll(`#${this.options.tableName} > tr > td.${selector} > input`);
        const arr = [];

        column.forEach((item) => {
            arr.push({
                id: item.id,
                text: item.value
            });
        });
        return arr;
    }

    storeLocalStorage(data, name) {
        localStorage.removeItem(name);
        localStorage.setItem(name, JSON.stringify(data));
    }

    setExpirationDate(){
        let date = new Date();
        let expDate = date;

        // This is for testing purposes
        // let minutes = date.getMinutes();
        // expDate.setMinutes(minutes + 1);

        // This is for the actual functionality
        let month = date.getMonth();
        let day = date.getDate();
        let year = date.getFullYear();
        
        if (day > 28) {
            expDate.setDate(28);
        }

        if (month === 11) {
            expDate.setFullYear(year + 1);
            expDate.setMonth(0);
        } else {
            expDate.setMonth(month + 1);
        }
        
        return expDate;
    }

    saveForm(arr, str) {
        let storage = {};
        let item = '';
        arr.forEach((value) => {
            if (/select2/g.test(value) && document.querySelector(`#${value}`) !== null) {
                document.querySelector(`#${value}`).textContent !== 'Type to search accounts' ? item = document.querySelector(`#${value}`).textContent.match(/([0-9])+/g)[0] : item = '';
            } else if (/accommodation/g.test(value) && document.querySelector(`#${value}`) !== null) {
                item = document.querySelector(`#${value}`).checked;
            } else if (/column/g.test(value)) {
                item = this.saveColumn(value);
            } else if (/tbody/g.test(value)) {
                document.querySelector(value) === null ? item = '' : item = document.querySelector(value).innerHTML;
            } else if (document.querySelector(`#${value}`) !== null) {
                item = document.querySelector(`#${value}`).value;
            }
            storage[value] = item;
        });
        storage["expirationDate"] = this.setExpirationDate();
        this.storeLocalStorage(storage, str);
    }

    addItems(arr) {
        arr.forEach((item) => {
            document.querySelector(`#${item.id}`).value = item.text;
        });
    }

    checkIfSaved() {
        return document.getElementsByName('_method').length === 0;
    }

    populateForm(str) {
        let storage = JSON.parse(localStorage.getItem(str));
        
        if(undefined !== storage.memo_order_ship_to && storage.memo_order_ship_to == ""){
          return false; // don't fill in a blank address
        }

        if(undefined !== storage.book_order_ship_to && storage.book_order_ship_to == ""){
          return false; // don't fill in a blank address
        }

        Object.keys(storage).forEach((key) => {
            if (/select2/g.test(key) && document.querySelector(`#${key}`) !== null) {
                this.options.loadAddress(storage[key]);
                this.accountFinder(storage[key], this.storageContains('address'));
            } else if (/accommodation/g.test(key) && document.querySelector(`#${key}`) !== null) {
                document.querySelector(`#${key}`).checked = storage[key];
                $(`#${key}`).change();
            } else if (/memo_order_account/g.test(key) && document.querySelector(`#${key}`) !== null) {
                const options = document.querySelectorAll(`#${key} > option`);
                options.forEach((item) => {
                    if (item.value === storage[key]) {
                        item.selected = 'selected';
                    }
                });
            } else if (/column/g.test(key)) {
                this.addItems(storage[key]);
            } else if (/tbody/g.test(key)) {
              if(document.querySelector(key)) {
                storage[key] === null ? document.querySelector(key).innerHTML = '' : document.querySelector(key).innerHTML = storage[key];
              }
            } else if (document.querySelector(`#${key}`) !== null) {
                document.querySelector(`#${key}`).value = storage[key];
            }
        });
    }

    accountFinder(value) {
        const select = this.options.select().find('.accountpicker');
        $.getJSON(`/accounts/${value}`, data => {
            let option = new Option(`${data.name} (${data.brand.toUpperCase()} - #${data.id})`, data.id, true, true);
            select.append(option).trigger('change');
        }).then((data) => {
            let { max_daily_memos } = data;
            if (max_daily_memos === -1) {
                max_daily_memos = 'none';
            }
            this.options.select()
                .find('.account-memos')
                .text(
                    // prettier-ignore
                    `Daily Limit: ${max_daily_memos} | Current Daily: ${data.current_daily_memos}`
                );
        });
    }
}
