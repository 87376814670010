import $ from 'jquery';
import 'select2';

export function initialize_pattern_picker() {
  return this.each(function() {
    $(this)
      .select2({
        theme: 'bootstrap4',
        placeholder: 'Pattern Search',
        minimumInputLength: 3,
        allowClear: true,
        ajax: {
          url: '/products/pattern_search',
          data: function (params) {
            if($('#pl_search').is(':checked') && $('#customer').val() !== null){
              params.private_label_account_id = $('#customer').val()
            }

            return params;
          },
          processResults: data => ({
            results: data.patterns.map(pattern => ({
              id: pattern.pattern.concat(' | ').concat(pattern.brand),
              text: `${pattern.pattern} (${BRAND_ABBREVIATIONS[pattern.brand]})`,
              products: data.products.filter(
                product => product.pattern === pattern.pattern && product.brand === pattern.brand
              ),
            })),
          }),
        },
      })
      .on('select2:select', e => {
        const color = $($(this).data('colorpicker'));
        color.empty();
        color.append(
          $('<option>')
            .attr('value', '-1')
            .text('All Colors')
        );

        if(undefined !== e.params.data){
          e.params.data.products.forEach(product => {
            color.append(
              $('<option>')
                .attr('value', product.id)
                .text(`${product.color} - #${product.id}`)
            );
            if(color.hasClass('select2-hidden-accessible')){
              color.select2('destroy')
            }
            color.select2({theme: 'bootstrap4'});
          });
        }
      });
  });
}

$.fn.extend({ initialize_pattern_picker });

$(() => {
  $('.patternpicker').initialize_pattern_picker();
});
