import $ from 'jquery';

import { connectDatatable } from './filters';

$(() => {
  $(document).on('click', '.mini-storage-lease-payments-table-toggle', function(){
    let tr = $(this).closest('tr');
    let row = $(this).parents('table').dataTable().api().row( tr );
    let btn = $(this)

    if(btn.hasClass('open')){
      btn.addClass('closed').removeClass('open');
      row.child.hide();
    } else {
      $.ajax({
        url: btn.attr('data-child-link'),
        type: 'GET',
        dataType: 'html',
      }).done(function(html) {
        if (!$('#fullcalendar-meeting-form').hasClass('d-none')) {
          let highlighted_html = $(html).highlight( btn.parents('table').dataTable().api().search() );  
          row.child(highlighted_html.html(), 'bg-white').show();
          btn.addClass('open').removeClass('closed');
        }
      });
    }
  });

  $(document).on('change', '#is_lease_active', function(){
    if($(this).val() == "true"){
      $('.lease-end-date-field').addClass('d-none');
    } else {
      $('.lease-end-date-field').removeClass('d-none');
    }
  });

  connectDatatable({
    rootSelector: '.mini-storage-lease-filters',
    datatableSelector: '.datatable_mini_storage_leases',
    builderAttrs: {
      defaultField: 'lease_start_at',
    },
  });
});
