import $ from 'jquery';
import 'pickadate-webpack/lib/picker'
import 'pickadate-webpack/lib/picker.date'

function initialize_datepicker() {
  return this.each(function() {
    $(this)
      .pickadate({
        container: 'body',
        format: 'mm/dd/yyyy',
        selectYears: true,
        selectMonths: true,
        editable: true
      }).on('mousedown click focus', function(e){
        $(e.currentTarget).pickadate('open')
        e.stopPropagation();
        e.preventDefault();
      });
  });
}

$.fn.extend({ initialize_datepicker });

$(() => {
  $('.datepicker').initialize_datepicker();
});
