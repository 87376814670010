import { extend } from 'lodash';

import $ from 'jquery';
import { open_drawer, close_drawer } from './drawer';
import { updateMapLinks } from './addresses';
import { refresh_task_list } from './tasks';
import { refresh_all_datatables } from './datatables.js';
import { refresh_calendar } from './meetings.js';
import { toast } from './toast.js';
import { init as initMemoOrders } from './memo_orders';
import { init as initBookOrders } from './book_orders';
import { fire as railsFire } from '@rails/ujs';

extend(window, {
  $,
  jQuery: $,
  open_drawer,
  close_drawer,
  updateMapLinks,
  refresh_task_list,
  refresh_all_datatables,
  refresh_calendar,
  toast,
  initMemoOrders,
  initBookOrders,
  railsFire
});
