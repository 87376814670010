import $ from 'jquery';

import { connectDatatable } from './filters';

$(() => {
  connectDatatable({
    rootSelector: '.birdseye-filters',
    datatableSelector: '.datatable_birds_eye',
    builderAttrs: {
      defaultField: 'name',
    },
  });

  $('.blended-lead-account-selector').select2({
    minimumInputLength: 3,
    allowClear: true,
    placeholder: 'Search Accounts/Leads',
    ajax: {
      url: '/reports/birds-eye/search',
      dataType: 'json'
    }
  });

  $('.blended-lead-account-selector').on('select2:select', function (e) {
    var data = e.params.data;
    $('#birdseye_record_type').val(data.record_type);
  });
});
