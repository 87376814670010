import m from 'mithril';

const OPERATORS = [
  { value: 'is_equal', label: 'Is equal' },
  { value: 'is_not_equal', label: 'Is not equal' },
  { value: 'starts_with', label: 'Starts with' },
  { value: 'does_not_start_with', label: 'Does not start with' },
  { value: 'ends_with', label: 'Ends with' },
  { value: 'does_not_end_with', label: 'Does not end with' },
  { value: 'contains', label: 'Contains' },
  { value: 'does_not_contain', label: 'Does not contain' },
  { value: 'is_blank', label: 'Is blank' },
  { value: 'is_not_blank', label: 'Is not blank' },
];

const TextWidget = {
  view({ attrs }) {
    return m('.d-flex', [
      m(
        'select.custom-select.custom-select-sm.w-50.mr-2',
        { onchange: e => (attrs.condition.operator = e.target.value) },
        OPERATORS.map(({ value, label }) =>
          m(
            'option',
            { value, selected: value === attrs.condition.operator },
            label
          )
        )
      ),

      attrs.condition.operator !== 'is_blank' && attrs.condition.operator !== 'is_not_blank' &&
        m('input.form-control.form-control-sm.w-50[type=text]', {
          placeholder: 'Value',
          value: attrs.condition.value,
          oninput: e => (attrs.condition.value = e.target.value),
        }),
    ]);
  },
};

export default TextWidget;
