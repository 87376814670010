import $ from 'jquery';

$(() => {
  const targeted = $('.datatable_accounts .column_targeted, .datatable_targeted_accounts .column_targeted');
  if (targeted.length > 0) {
    targeted
      .empty()
      .append($('<span class="sr-only">').text('Favorited'))
      .append($('<i class="fa fa-star mr-2">'));
  }

  $('.datatable_accounts,.datatable_targeted_accounts').on('click', '.targeted-toggle', function() {
    $(this)
      .find('i')
      .toggleClass('fa-star')
      .toggleClass('fa-star-o');

    $(this)
      .find('div')
      .text($(this).find('i').hasClass('fa-star') ? 'Yes' : 'No');

    const accountId = $(this)
      .parents('table')
      .DataTable()
      .row($(this).parents('tr'))
      .data().id;

    $.post(`/user-account-flags/${accountId}/toggle-targeted`);
  });
});
