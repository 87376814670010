import m from 'mithril';

const OPERATORS = [
  { value: 'is_equal', label: 'Is equal' },
  { value: 'is_not_equal', label: 'Is not equal' },
  { value: 'less_than', label: 'Less than' },
  { value: 'less_than_or_equal_to', label: 'Less than or equal to' },
  { value: 'greater_than', label: 'Greater than' },
  { value: 'greater_than_or_equal_to', label: 'Greater than or equal to' },
  { value: 'is_blank', label: 'Is blank' },
];

const NumericWidget = {
  view({ attrs }) {
    return m('.d-flex', [
      m(
        'select.custom-select.custom-select-sm.w-50.mr-2',
        { onchange: e => (attrs.condition.operator = e.target.value) },
        OPERATORS.map(({ value, label }) =>
          m(
            'option',
            { value, selected: value === attrs.condition.operator },
            label
          )
        )
      ),

      attrs.condition.operator !== 'is_blank' &&
        m('input.form-control.form-control-sm.w-50[type=text]', {
          placeholder: 'Value',
          value: attrs.condition.value,
          oninput: e => (attrs.condition.value = e.target.value),
        }),
    ]);
  },
};

export default NumericWidget;
