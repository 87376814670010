import $ from 'jquery';

$(() => {
  $(document).on('click', '.toggle-note-edit', function(){
    $(this).parents('.note-item').addClass('edit');
  });

  $(document).on('click', '.cancel-note-edit', function(){
    $(this).parents('#new_note').remove();
    $(this).parents('.note-item').removeClass('edit');
  });
});
