import $ from 'jquery';

$(() => {
  function disableRequestButtons() {
    $('.request-button').prop('disabled', true);
  }

  $('.form-entries-form.edit')
    .on('change', disableRequestButtons)
    .on('datepicker:change', disableRequestButtons)
    .on('timepicker:change', disableRequestButtons)
    .on('select2:select', disableRequestButtons)
    .find('.add-button, .remove-button')
    .on('click', disableRequestButtons);

  $(document).on('input', '#reject-modal #rejection_reason', e => {
    const empty = e.target.value.trim() === '';

    $('#reject-modal button[type=submit]')[0].disabled = empty;
  });
});
