import $ from 'jquery';
import { method } from 'lodash';

export function updateMapLinks() {
  if (navigator.userAgent.indexOf('iPad') === -1) {
    return;
  }

  const elements = document.querySelectorAll('a.map-link');
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    element.href = element.dataset.appleMapsUrl;
    element.target = '';
  }
}

$(document).on('click', '#delete_address_button', function() {
  var address_id = $(this).attr('data-address-id');
  $.ajax({
    method: 'DELETE',
    url: `/addresses/${address_id}`,
    complete: function() {
      location.reload();
      toast('info', 'Address deleted');
    },
    error: function() {
      toast('info', 'Error deleting address');
    }
  });
});

$(() => {
  updateMapLinks();
});
