import $ from 'jquery';
import { ajax } from '@rails/ujs';
import { debounce } from 'lodash';

const ESCAPE = 27;

function show() {
  $('.search-overlay').removeClass('d-none');

  setTimeout(() => {
    $('.search-overlay').addClass('open');

    $('#search').focus();
  }, 1);
}

function hide() {
  $('.search-overlay')
    .removeClass('open')
    .on('transitionend', function(e) {
      if (e.target === e.currentTarget) {
        $(this).addClass('d-none');
        $(this).off('transitionend');
      }
    });
}

$(() => {
  $(document).on('click', '.search-link', show);

  $(document).on('click', '.search-overlay', e => {
    if (e.target === e.currentTarget) {
      hide();
    }
  });

  $(document).on('click', '.site-search-close', hide);

  $(document).on('keydown', e => {
    if (e.keyCode === ESCAPE) {
      hide();
    }
  });

  $('#search').on(
    'keyup paste',
    debounce(() => {
      const q = $('#search').val();
      if (q.length < 2) return false;

      $('.search-loader').removeClass('d-none');
      $('.search-results').addClass('d-none').empty();

      ajax({
        type: 'GET',
        url: '/search',
        data: $.param({ q }),
        dataType: 'script',
      });
    }, 500)
  );
});
