import $ from 'jquery';

$(() => {
  const header = $('.account-header');
  const breadcrumbs = $('.breadcrumbs');

  if (header.length === 0 || breadcrumbs.length === 0) {
    return;
  }

  const offset =
    breadcrumbs.get(0).getBoundingClientRect().bottom + window.scrollY;

  function update() {
    header.toggleClass('shadow-sm', window.scrollY > offset);
  }

  $(window).on('scroll', () => requestAnimationFrame(update));

  update();
});
