import $ from 'jquery';
import { ajax } from '@rails/ujs';
import qs from 'qs';

$(() => {
  $('.drawer').on('change', '.activity-note-filter', function(){
    let params = {
      note_types: [],
      sort_direction: $('#activity_sort').val()
    }

    $('input.activity-note-filter').each(function(i, input_el){
      if($(input_el).is(':checked')){
        params['note_types'].push($(input_el).val());
      }
    });

    ajax({
      type: 'GET',
      url: '/uni/' + $('#activity_uni').val() + '/activity',
      data: qs.stringify(params),
      dataType: 'script',
    });

  });
});
