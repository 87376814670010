import m from 'mithril';

const Footer = {
  view({ attrs }) {
    return m('.d-flex.p-2.border-top', [
      m(
        'button.btn.btn-sm.btn-outline-success.px-3.ml-auto[type=button]',
        { onclick: attrs.addCondition },
        m('i.fa.fa-plus')
      ),
    ]);
  },
};

export default Footer;
