import $ from 'jquery';

$(() => {
  $(document).on('click', '.collapse-header', function() {
    if ($(this).hasClass('collapsed')) {
      $(this).removeClass('collapsed');
      $(this).parent().find('.collapse-content').slideDown();
    } else {
      $(this).addClass('collapsed');
      $(this).parent().find('.collapse-content').slideUp();
    }
  });
});
