import $ from 'jquery';
import 'select2';

export function initialize_account_picker() {
  return this.each(function() {
    $(this)
      .prop('readonly', true)
      .select2({
        theme: 'bootstrap4',
        minimumInputLength: 2,
        allowClear: true,
        placeholder: 'Type to search accounts',
        templateSelection: state => {
          if (state.id == null || state.name == null) {
            return state.text;
          }
          return `(${state.text}) ${state.name} `;
        },
        ajax: {
          url: '/accounts/search',
          dataType: 'json',
        },
      });
  });
}

$.fn.extend({ initialize_account_picker });

$(() => {
  $('.accountpicker').initialize_account_picker();
});
