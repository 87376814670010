import $ from 'jquery';
import m from 'mithril';
import qs from 'qs';

import Builder from './Builder';

export function connectDatatable(options) {
  const root = document.querySelector(options.rootSelector);
  const datatable = $(options.datatableSelector);

  if (root == null || datatable.length === 0) {
    return;
  }

  const fields = JSON.parse(root.dataset.fields);
  const sources = JSON.parse(root.dataset.sources);

  function applyFilter(filter) {
    const table = $(datatable).find('table');
    const options = $(datatable).data('options');

    const instance = $(table).DataTable();

    const url =
      options.ajax.url +
      '?' +
      qs.stringify({ filter }, { arrayFormat: 'brackets' });

    instance.ajax.url(url).load();
  }

  m.mount(root, {
    view() {
      return m(Builder, {
        id: root.id,
        ...root.dataset,
        ...options.builderAttrs,
        fields,
        sources,
        applyFilter,
      });
    },
  });
}
