require('@rails/ujs').start();
require('@rails/activestorage').start();

require('bootstrap/dist/js/bootstrap');

// DataTables
require('jszip');
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-buttons/js/buttons.flash.js');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-buttons/js/buttons.print.js');
require('datatables.net-colreorder-bs4');
require('datatables.net-fixedcolumns-bs4');
require('datatables.net-fixedheader-bs4');
require('datatables.net-responsive-bs4');
require('datatables.net-scroller-bs4');

// Datatables is a little finnicky
// https://datatables.net/forums/discussion/comment/142540/#Comment_142540 
import * as JSZip from 'jszip';
window.JSZip = JSZip;

require('jquery-highlight');

$.fn.modal.Constructor.prototype._enforceFocus = function() {};

const files = require.context('../src', true, /\.js$/);
files.keys().forEach(files);
