import $ from 'jquery';

export function open_drawer(content, options = {}) {
  $('.drawer-overlay').removeClass('d-none');
  setTimeout(() => $('.drawer-overlay').addClass('open'), 1);
  $('.drawer-title').text(options.title || '');
  $('.drawer-content').html(content);
  if (options.wide) {
    $('.drawer').addClass('wide');
  }
  $('.drawer').addClass('open');
}

export function close_drawer() {
  $('.drawer-overlay')
    .removeClass('open')
    .one('transitionend', function() {
      $('.drawer').removeClass('wide');
      $('.drawer-overlay').addClass('d-none');
      $('.drawer-content').empty();
    });
  $('.drawer').removeClass('open');
}

$(function() {
  $('.drawer-overlay').on('click', close_drawer);
});
