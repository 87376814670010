import $ from 'jquery';
import { fire } from '@rails/ujs';
import { debounce } from 'lodash';

$(() => {
  $(document).on('click', '.field-decrement', debounce(function(){
    const number_el = $(this).parents('.input-group').find('input');
    const current_val = parseFloat(number_el.val());
    if(current_val && current_val > 0){
      number_el.val(current_val - 1);
    } else {
      number_el.val(0);
    }

    if($(this).hasClass('field-increment-submit')){
      fire($(this).parents('form')[0], 'submit');
    }
  }, 250));

  $(document).on('click', '.field-increment', debounce(function(){
    const number_el = $(this).parents('.input-group').find('input');
    const current_val = parseFloat(number_el.val());
    if(current_val){
      number_el.val(current_val + 1);
    } else {
      number_el.val(1);
    }

    if($(this).hasClass('field-increment-submit')){
      fire($(this).parents('form')[0], 'submit');
    }
  }, 250));
});
