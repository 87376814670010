import $ from 'jquery';
import Sortable from 'sortablejs';

$(() => {
  $(document).on('click', '.columns-button', function(){
    const dt = $(this).parents('.dataTables_wrapper').first().find('.dataTable').first().dataTable().api();

    const column_map = [];

    $.each(dt.columns().header(), function(i, th){
      column_map.push($(th));
    });

    $('#column-modal').find('.column-list').html('');

    column_map.forEach(function(element, position){
      const column_button = $('<div>').addClass('column-item m-2 btn btn-sm btn-light text-center')
      
      if(dt.column(position).visible()){
        column_button.addClass('active');
      }

      column_button.text(element.text());

      if(!element.hasClass('no-column-control')){
        column_button.addClass('column-control-button');
      } else {
        column_button.removeClass('active').prop('disabled', true).hide();
      }

      $('#column-modal').find('.column-list').append(column_button);
    });

    $('#column-modal').modal('show');

    if(!$('#column-modal').find('.column-list').hasClass('sortablejs')){
      new Sortable($('#column-modal').find('.column-list')[0], {
        animation: 300,
        draggable: '.column-control-button',
        onEnd: function (evt) {
          dt.colReorder.move(evt.oldIndex, evt.newIndex);
        }
      });
      $('#column-modal').find('.column-list').addClass('sortablejs')
    }

    $(document).off('click', '.column-item').on('click', '.column-item', function(){
      if(!$(this).hasClass('column-control-button')) return false;
      $(this).toggleClass('active');
      let col_index = $(this).index();
      dt.column(col_index).visible($(this).hasClass('active'));
    });

  });
});
