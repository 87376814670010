import $ from 'jquery';

$(function() {
  // Delete Teritory Button
  $('.delete-territory-button').on('click', function(){
    if(!confirm('Delete this territory? This is really not recommended.')) return false;

    // Ajax Delete
    var territory_id = $('#territory_id').val();
    $.ajax({
      url: '/admin/territories/' + territory_id,
      type: 'DELETE'
    })
    .done(function() {
      window.location.href = '/admin/territories';
    });
  });
});
