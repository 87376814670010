import $ from 'jquery';

export default class AddressHelper {
    constructor(options) {
      this.options = options;
      this.ADDRESS_FIELDS = ['usage', 'name', 'street', 'street-2', 'street-3', 'city', 'state', 'zip', 'country'];
      this.address;
      this.set_address_form_load_event();
      this.set_address_modal_event();
    }

    set_account(value){
      $('#account-address-id').val(value);
    }

    get_account() {
      return $('#account-address-id').val();
    }

    populate_table() {
      var account = this.get_account();
      $.ajax({
        method: 'GET',
        url: `/addresses/${account}`,
        error: function () {
          toast('warning', 'Error loading addresses');
        }
      });
    }

    set_address_form_load_event() {
      var helper = this;
      $(document).on('click', `.${helper.options.load_button}`, function () {
        var button = $(this);
        helper.ADDRESS_FIELDS.forEach(function(field) {
          if (field !== 'usage'){
            $(`#${helper.options.form_fields[field]}`).val(button.attr(`data-${field}`));
          }
        });
        $("#addressModal").modal('hide');
      });
    }

    set_address_modal_event() {
      var helper = this;
      $(document).on('click', `#${helper.options.load_addresses_button}`, function () {
        var account = helper.get_account();
        if (account === "") {
          toast('info', 'Please Select a Customer');
        } else {
          $('#address_modal_body').html('<p class="text-center pt-5">Loading <i class="fa fa-spinner fa-spin"></i></p>');
          $("#addressModal").modal('toggle');
          helper.populate_table();
        }
      });
    }
}
