import $ from 'jquery';
import { ajax } from '@rails/ujs';
import qs from 'qs';
import { toast } from './toast';
import AddressHelper from './address_helper'

const form = () => $('#book-order-form');
const addItemForm = () => form().find('.add-item-form');
const itemsTable = () => form().find('.items-table');
const book_address_loader = new AddressHelper({
  form_fields: {
    'name': 'book_order_ship_to',
    'street': 'book_order_street',
    'street-2': 'book_order_street_2',
    'street-3': 'book_order_street_3',
    'city': 'book_order_city',
    'state': 'book_order_state',
    'zip': 'book_order_zip'
  },
  load_addresses_button: 'load-alternate-book-address',
  load_button: 'load-address'
});

function accountPicker() {
  form()
    .find('.accountpicker')
    .on('select2:select', e => {
      $.getJSON(`/accounts/${e.params.data.id}`, data => {
        if ($('#book_order_ship_to').val() === '') {
          $('#book_order_ship_to').val(data.primary_address.name);
        }
        ['street', 'street_2', 'street_3', 'city', 'state', 'zip'].forEach(
          field => {
            $(`#book_order_${field}`).val(data.primary_address[field]);
          }
        );
      });

      book_address_loader.set_account(e.params.data.id);

      const brandAccounts = form().find('.brand-accounts-wrapper');
      ajax({
        type: 'GET',
        url: brandAccounts.data('url'),
        data: qs.stringify({ account_id: e.params.data.id }),
        dataType: 'script',
      });
    });
}

function addItemButton() {
  form()
    .find('.add-button')
    .on('click', () => {
      const data = {
        book_id: $('#book_id').val(),
        quantity: $('#quantity').val(),
        action_type: $('#action_type').val(),
        customer: $('#customer').val(),
      };

      if (data.book_id === '' || data.customer == null) {
        return;
      }

      ajax({
        type: addItemForm().data('method'),
        url: addItemForm().data('url'),
        data: qs.stringify(data),
        dataType: 'script',
      });
    });
}

function removeItemButton() {
  itemsTable().on('click', '.remove-button', function() {
    $(this)
      .parents('tr')
      .remove();
  });
}

$(document).on('click', '.book-action-button', function(e){
  e.preventDefault();
  if($(this).hasClass('active')){
    $(this).removeClass('active').addClass('force-no-hover');
    return;
  }
  $(this).parent().find('.book-action-button.active').removeClass('active').addClass('force-no-hover');
  $(this).addClass('active').removeClass('force-no-hover');
});

$(document).on('click', '.add-collection-books', function(){	
  if($('#customer').val() == null){	
    toast('info', 'Please Select a Customer');	
    return false;	
  }	

  $('.collection-book-item').each(function(i, row){
    let qty = $(row).find('select.book-qty').val();
    let action_type = $(row).find('.book-action-button.active').text();
    if(qty !== '' && action_type !== ''){

      let data = {
        book_id: $(row).attr('data-book-id'),
        quantity: qty,
        action_type: action_type,
        customer: $('#customer').val()
      }	

      ajax({
        type: addItemForm().data('method'),
        url: addItemForm().data('url'),
        data: qs.stringify(data),
        dataType: 'script'
      });

      $(row).find('select.book-qty').val(1);
      $(row).find('.book-action-button').removeClass('active');
    }
  });
});

function reset_table() {
  $('.collection-book-item').each(function (index, item) {
    item.removeAttribute('style');
  });
}

function update_table(val) {
  reset_table();
  var search = val.toLowerCase();
  if (val !== '') {
    $('.collection-book-item > .book-title').each(function (index, item) {
      var text = item.textContent.toLowerCase();
      if (!(text.includes(search))) {
        $(this).parent().css('display', 'none');
      }
    });
  }
}

document.addEventListener('search', function() {
  if($('#book_search').length){
    update_table($('#book_search').val());
  }
});

$(document).on('keyup', '#book_search', function () {
  update_table($(this).val());
});

export function init() {
  $('.add-item-form').on('change', 'select.book', function(){
    let book_id = $(this).val();
    if(!book_id) return false;
    $.ajax({
      url: '/books/' + book_id + '/allotments'
    }).done(function(data){
      if(data.length){
        let allotment = data[0]
        $('.current-allotments').text('Remaining: ' + allotment.remaining + ' | Used: ' + allotment.used + ' | Allotted: ' + allotment.allocated)
      } else {
        $('.current-allotments').text('No Allotments Found');
      }
    });
  });

  $('#dropdown-menu > .dropdown-item').click(function() {
    if($('#customer').val() == null){
      toast('info', 'Please Select a Customer');
      $('.dropdown-menu').removeClass('show');
      return false;
    }

    let collection_id = $(this).attr("data-value");
    $('#drop-down-text').text($(this).text());
    collection_id === '0' ? $('#book_search').prop('disabled', true) : $('#book_search').prop('disabled', false);
    if(!collection_id){
      $('.single-book-form').show();
      $('.multi-book-form').hide();
      $('.bookpicker').find('.book').parent().find('.select2').show();
    } else {
      $('.single-book-form').hide();
      $('.bookpicker').find('.book').parent().find('.select2').hide();
      ajax({
        type: 'get',
        url: '/book_collections/' + collection_id + '/collection-books-form',
        data: qs.stringify({customer: $('#customer').val()}),
        dataType: 'script',
      });
    }

  });

  accountPicker();
  addItemButton();
  removeItemButton();
}

$(init);
