import $ from 'jquery';
import { ajax, fire } from '@rails/ujs';

import { close_drawer } from './drawer';

export function refresh_task_list(clear_form = false){
  if(document.getElementById('tasks-list-form') != null){
    $('#tasks-list-form').find('input').val($('#show-completed-tasks').is(':checked') ? true : '');
    fire(document.getElementById('tasks-list-form'), 'submit');
    if (clear_form){
      $('#task-form').addClass('d-none').html('');
      $('#task-form-placeholder').removeClass('d-none');
    }
  }

  if($('#to-do-tab').length){
    ajax({
      type: 'GET',
      url: '/dashboard/tasks_widget',
      dataType: 'script'
    });
  }
}

$(() => {
  $('#show-completed-tasks').on('click', refresh_task_list);

  $('#task-list,#active-tasks').on('click', '.complete-task-toggle', function(){
    $(this).parent().toggleClass('completed');
    $(this).find('i').toggleClass('fa-check-square-o').toggleClass('fa-square-o');

    var task_id = $(this).parent().attr('data-id');
    var list_id = $(this).parent().attr('data-list-id');
    var form = document.getElementById('toggle-task-complete');
    form.setAttribute(
      'action',
      '/tasks/' + encodeURIComponent(task_id) + '/toggle-complete?list_id=' + encodeURIComponent(list_id)
    );
    fire(form, 'submit');
  });

  $('#task-form').on('click', '.cancel-task-edit', function(){
    $('#task-form').addClass('d-none').html('');
    $('#task-form-placeholder').removeClass('d-none');
    $('#task-list').find('tr.active').removeClass('active');
  });

  $('.drawer').on('click', '.cancel-task-edit', function(){
    close_drawer();
  });
});
