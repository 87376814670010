import $ from 'jquery';
import { ajax } from '@rails/ujs';
import qs from 'qs';

$(() => {
  $(document).on('change', '.single-field-form-element', function(){
    let form_data = {}
    form_data[$(this).attr('data-key')] = $(this).val();

    ajax({
      type: 'patch',
      url: $(this).attr('data-url'),
      data: qs.stringify(form_data),
      dataType: 'script',
    });
  });
});
