import $ from 'jquery';	
import 'select2';	

export function initialize_book_picker() {	
  return this.each(function() {	
    const target = $($(this).data('target'));	
    if (target.length === 0) {	
      throw new Error(`Couldn't find target ${target}`);	
    }	

    let collection_id = '';	

    const collection = $(this)	
      .find('.collection')	
      .prop('readonly', true)	
      .select2({ theme: 'bootstrap4' });	

    const book = $(this)	
      .find('.book')	
      .prop('readonly', true)	
      .select2({	
        theme: 'bootstrap4',	
        allowClear: true,	
        placeholder: 'Type to search books',	
        ajax: {	
          url: '/books/autocomplete',	
          data: params => ({ ...params, collection_id }),	
        },	
      });	

    collection.on('select2:select', e => {	
      collection_id = e.params.data.id;	

      book.val(null).trigger('change');	
    });	

    book.on('select2:select', e => {	
      target.val(e.params.data.id);	
    });	
  });	
}	

$.fn.extend({ initialize_book_picker });	

$(() => {	
  $('.bookpicker').initialize_book_picker();	
});	