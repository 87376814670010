import $ from 'jquery';

import { connectDatatable } from './filters';

$(() => {
  connectDatatable({
    rootSelector: '.lead-filters',
    datatableSelector: '.datatable_leads',
    builderAttrs: {
      defaultField: 'company_name',
    },
  });

  $(document).on('click', '.social-link', function(){
    var url = $(this).parents('.input-group').first().find('input').val();
    if(!url){
      toast('warning', 'The URL cannot be blank');
      $(this).parents('.input-group').first().find('input').focus();
    } else {
      window.open(url);
    }
  });
});
