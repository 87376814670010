import $ from 'jquery';
import 'select2';

export function initialize_mini_storage_lease_picker() {
  return this.each(function() {
    $(this)
      .prop('readonly', true)
      .select2({
        theme: 'bootstrap4',
        minimumInputLength: 2,
        allowClear: true,
        placeholder: 'Type to search leases',
        ajax: {
          url: '/mini_storage_leases/search',
          dataType: 'json',
        },
      });
  });
}

$.fn.extend({ initialize_mini_storage_lease_picker });

$(() => {
  $('.ministorageleasepicker').initialize_mini_storage_lease_picker();
});
