import $ from 'jquery';
import { ajax, fire } from '@rails/ujs';

$(() => {
  $('.history-range-button').on('click', function(){
    $('.history-range-button.active').removeClass('active');
    $(this).addClass('active');
  });

  ajax({
    type: 'GET',
    url: '/dashboard/tasks_widget',
    dataType: 'script'
  });

  if($('#initial-history-widget-link').length){
    fire($('#initial-history-widget-link')[0], 'click');
  }

  if($('#planned-meetings-tab').length){
    fire($('#planned-meetings-tab')[0], 'click');
  }

  if($('#territory-totals-wrapper').length){
    ajax({
      type: 'GET',
      url: '/dashboard/territory_totals_widget',
      dataType: 'script',
    });
  }
});
