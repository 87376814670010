import m from 'mithril';
import uuid from 'uuid/v4';
import { cloneDeep } from 'lodash';
import { csrfParam, csrfToken } from '@rails/ujs';

import Header from './Header';
import Widget from './Widget';
import Footer from './Footer';

function Builder({ attrs }) {
  let filters = [];
  let filter;
  let status;

  function loadFilters() {
    m.request(attrs.url)
      .then(result => {
        result.forEach(filter => {
          filter.conditions.forEach(condition => {
            condition.id = uuid();
          });
        });
        filters = result;
      })
      .catch(handleError);
  }

  function setFilter(newFilter) {
    filter = cloneDeep(newFilter);
  }

  function newFilter() {
    filter = { conditions: [] };
    addCondition();
  }

  function saveFilter(name) {
    status = 'loading';
    m.request({
      method: 'POST',
      url: attrs.url,
      body: {
        [csrfParam()]: csrfToken(),
        filter: { ...filter, name },
      },
      withCredentials: true,
    })
      .then(() => {
        loadFilters();
        flashStatus('saved');
      })
      .catch(handleError);
  }

  function clearFilter() {
    filter = null;
    status = null;
  }

  const storageId = `${attrs.id}:filter`;

  function storeFilter() {
    try {
      localStorage.setItem(storageId, JSON.stringify(filter));
    } catch (err) {}
  }

  function restoreFilter() {
    let result;
    try {
      result = localStorage.getItem(storageId);
    } catch (err) {}
    if (result == null) {
      return;
    }
    try {
      result = JSON.parse(result);
    } catch {
      return;
    }
    setFilter(result);
  }

  function addCondition() {
    const defaultField = attrs.fields.find(f => f.name === attrs.defaultField);

    filter.conditions.push({
      id: uuid(),
      field: defaultField.name,
      type: defaultField.type,
      ...defaultField.defaults,
      options: defaultField.options,
    });
  }

  function replaceCondition(condition, field) {
    filter.conditions.splice(filter.conditions.indexOf(condition), 1, {
      id: uuid(),
      field: field.name,
      type: field.type,
      ...field.defaults,
      options: field.options,
    });
  }

  function removeCondition(condition) {
    if (filter.conditions.length === 1) {
      clearFilter();
      return;
    }
    filter.conditions.splice(filter.conditions.indexOf(condition), 1);
  }

  let statusTimeout;

  function flashStatus(newStatus) {
    status = newStatus;
    if (statusTimeout != null) {
      clearTimeout(statusTimeout);
    }
    statusTimeout = setTimeout(() => {
      status = null;
      m.redraw();
      statusTimeout = null;
    }, 3000);
  }

  function handleError(error) {
    console.error(error); // eslint-disable-line no-console
    flashStatus('error');
  }

  let updateTimeout;

  return {
    oninit() {
      loadFilters();
      restoreFilter();
    },

    onupdate() {
      if (updateTimeout != null) {
        clearTimeout(updateTimeout);
      }
      updateTimeout = setTimeout(() => {
        storeFilter(filter);
        if (attrs.applyFilter != null) {
          attrs.applyFilter(filter);
        }
        updateTimeout = null;
      }, 500);
    },

    view() {
      return m('.border.rounded.bg-light', [
        m(Header, {
          url: attrs.url,
          filters,
          filter,
          status,
          setFilter,
          newFilter,
          saveFilter,
          clearFilter,
        }),

        filter != null && [
          filter.conditions.map(condition =>
            m(Widget, {
              key: condition.id,
              fields: attrs.fields,
              sources: attrs.sources,
              condition,
              replaceCondition,
              removeCondition,
            })
          ),

          m(Footer, { addCondition }),
        ],
      ]);
    },
  };
}

export default Builder;
