import $ from 'jquery';

$(() => {
  $('#territory-totals-wrapper').on('change', 'select,input', () => {
    if ($('select[name=territory_id]').val() === '') {
      $('.territory-totals-results').addClass('d-none');
      return false;
    }

    const params = {};

    $('#territory-totals-widget-form')
      .find('select')
      .each((i, select_el) => {
        if ($(select_el).val() !== '') {
          params[$(select_el).attr('name')] = $(select_el).val();
        }
      });

    params['calendar_or_fiscal'] = $('input[name=caledar_or_fiscal]:checked').val()

    $.get('/territory-totals', params, data => {
      $('.total_customers').text(data.total_customers);
      $('.ytd_sales').text(data.ytd_sales);
      $('.most_recent_visit').text(data.most_recent_visit);
      $('.ly_sales').text(data.ly_sales);
      $('.visits_in_60_days').text(data.visits_in_60_days);
      $('.py_sales').text(data.py_sales);
      $('.lyytd_sales').text(data.lyytd_sales);

      $('.territory-totals-results').removeClass('d-none');
    });
  });
});
