import $ from 'jquery';
import 'jquery-highlight';

export function refresh_all_datatables() {
  $('.dataTable').each(function(i, item) {
    $(item)
      .DataTable()
      .ajax.reload();
  });
}

$(() => {
  $('.datatable').each(function() {
    const table = $(this).find('table');
    const options = $(this).data('options');

    $.fn.dataTable.ext.errMode = 'none';

    table.on('error.dt', function(e, settings, techNote, message) {
      console.log('An error has been reported by DataTables: ', message);
    });

    var dt = table.DataTable(options);

    dt.on('draw', function() {
      var body = $(dt.table().body());
      body.unhighlight();
      body.highlight(dt.search());
    });
  });

  // NOTE: This is temporary. We only need it to clear out localStorage for
  // users that have tons of data there from the way we had DataTables
  // configured. Once that has happened we can remove this code.
  const expiredKeys = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key.indexOf('DataTables') === -1) {
      continue;
    }
    if (key.match(/accounts\/\d{1,7}$/) != null) {
      expiredKeys.push(key);
      continue;
    }
    let data;
    try {
      data = JSON.parse(localStorage.getItem(key));
    } catch (err) {
      continue;
    }
    if (
      typeof data.time === 'number' &&
      new Date().getTime() - data.time > 7200 * 1000
    ) {
      expiredKeys.push(key);
    }
  }
  expiredKeys.forEach(key => {
    localStorage.removeItem(key);
  });
});
