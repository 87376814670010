import $ from 'jquery';
import { memoize } from 'lodash';
import { ajax } from '@rails/ujs';
import { toast } from './toast';
import qs from 'qs';

const form = memoize(() => $('#pricing-sheet-form'));
const addItemForm = memoize(() => form().find('.add-item-form'));
const itemsTable = memoize(() => form().find('.items-table'));

function customerPicker() {
  form()
    .find('.customerpicker')
    .on('select2:select', e => {
      $.getJSON(`/uni/${e.params.data.id}/accounts`, data => {
        ['city', 'state', 'zip'].forEach(field => {
          $(`#pricing_sheet_${field}`).val(data[0].primary_address[field]);
        });

        const volumeTable = form().find('.volume-table');
        ajax({
          type: 'GET',
          url: volumeTable.data('url'),
          data: qs.stringify({ uni_number: e.params.data.id }),
          dataType: 'script',
        });
      });
    });
}

function itemType() {
  addItemForm()
    .find('#item_type')
    .on('change', updateItemType)
    .on('change', updatePricingType);

  updateItemType();
  updatePricingType();
}

function updateItemType() {
  const isSpecialPricing =
    addItemForm()
      .find('#item_type')
      .val() === 'Special Pricing';

  const hideFields = {
    '#pricing_type': !isSpecialPricing,
    '#discount_type': isSpecialPricing,
    '#discount_category': isSpecialPricing,
  };

  for (let [sel, hide] of Object.entries(hideFields)) {
    addItemForm()
      .find(sel)
      .parents('.field')
      .toggleClass('d-none', hide);
  }
}

function pricingType() {
  addItemForm()
    .find('#pricing_type')
    .on('change', updatePricingType);

  updatePricingType();
}

function updatePricingType() {
  const isSpecialPricing =
    addItemForm()
      .find('#item_type')
      .val() === 'Special Pricing';
  const isQuote =
    addItemForm()
      .find('#pricing_type')
      .val() === 'Q - Enter $ Amount True Quote';

  const hideFields = {
    '#quote_class': !isSpecialPricing || !isQuote,
  };

  for (let [sel, hide] of Object.entries(hideFields)) {
    addItemForm()
      .find(sel)
      .parents('.field')
      .toggleClass('d-none', hide);
  }
}

function addItemButton() {
  addItemForm()
    .find('.add-button')
    .on('click', () => {
      const data = {};

      addItemForm()
        .find('[name]')
        .each(function() {

          if($(this).attr('name') == 'amount'){
            let unit = $('.pricing-sheet-unit-toggle.active').text();
            if(unit == '%'){
              data[$(this).attr('name')] = $(this).val() + '%';
            } else {
              data[$(this).attr('name')] = '$' + $(this).val();
            }
          } else {
            data[$(this).attr('name')] = $(this).val();
          }
        });

      const isPermanent = addItemForm().find('#is_permanent');
      data.is_permanent = isPermanent.is(':checked') ? '1' : '';

      if(data.is_permanent){
        data['start_at'] = '';
        data['end_at'] = '';
      } else if( (data['start_at'] !== '' && data['end_at'] === '') || (data['end_at'] !== '' && data['start_at'] === '') ) {
        toast('warning', 'Please enter a start date AND an end date or select "Permanent"');
        return false;
      }

      ajax({
        type: addItemForm().data('method'),
        url: addItemForm().data('url'),
        data: qs.stringify(data, { arrayFormat: 'brackets' }),
        dataType: 'script',
      });
    });
}

function removeItemButton() {
  itemsTable().on('click', '.remove-button', function() {
    $(this)
      .parents('tr')
      .remove();
  });
}

$(() => {
  $('.pricing-sheet-unit-toggle').on('click', function(){
    $('.pricing-sheet-unit-toggle').removeClass('active');
    $(this).addClass('active').removeClass('disabled');
  });

  $('#discount_category').on('change', function(){
    if($('#brands').hasClass('select2-hidden-accessible')){
      $('#brands').select2('destroy');
    }
    if($(this).val() === 'Hardware (88)'){
      $('#brands').find('.non-hardware-option').prop('disabled', true);
      $('#brands').find('.hardware-option').prop('disabled', false);
    } else {
      $('#brands').find('.non-hardware-option').prop('disabled', false);
      $('#brands').find('.hardware-option').prop('disabled', true);
    }
    $('#brands').select2();
  });

  customerPicker();
  itemType();
  pricingType();
  addItemButton();
  removeItemButton();
});
