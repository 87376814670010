window.BRAND_ABBREVIATIONS = {
  fabricut: "F",
  trend: "T",
  sharris: "SH",
  stroheim: "S",
  vervain: "V",
  contract: "C",
  clarencehouse: "CH",
  export: "E",
};
