import $ from 'jquery';
import 'jquery-highlight';
import { connectDatatable } from './filters';

$(() => {
  $(document).on('click', '.order-item-table-toggle', function(){
    let tr = $(this).closest('tr');
    let row = $(this).parents('table').dataTable().api().row( tr );
    let btn = $(this)

    if(btn.hasClass('open')){
      btn.addClass('closed').removeClass('open');
      row.child.hide();
    } else {
      $.ajax({
        url: btn.attr('data-child-link'),
        type: 'GET',
        dataType: 'html',
      }).done(function(html) {
        if (!$('#fullcalendar-meeting-form').hasClass('d-none')) {
          let highlighted_html = $(html).highlight( btn.parents('table').dataTable().api().search() );  
          row.child(highlighted_html.html(), 'bg-white').show();
          btn.addClass('open').removeClass('closed');
        }
      });
    }
  });

  $(document).on('click', '.order-details .row-expand-toggle', function(){
    $(this).toggleClass('open').toggleClass('closed');
    $(this).parents('tr').next().toggleClass('d-none');
  });

  connectDatatable({
    rootSelector: '.order-filters',
    datatableSelector: '.datatable_orders',
    builderAttrs: {
      defaultField: 'account_id',
    },
  });
});

