import $ from 'jquery';

import { connectDatatable } from './filters';

$(() => {
  connectDatatable({
    rootSelector: '.book-filters',
    datatableSelector: '.datatable_books',
    builderAttrs: {
      defaultField: 'id',
    },
  });
});
