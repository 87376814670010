import $ from 'jquery';
import { ajax } from '@rails/ujs';
import qs from 'qs';

import { connectDatatable } from './filters';

$(() => {
  connectDatatable({
    rootSelector: '.forklift-filters',
    datatableSelector: '.datatable_forklifts',
    builderAttrs: {
      defaultField: 'year',
    },
  });

  $(document).on('click', '.update-forklift-hours-button', function(){
    let forklift_id = $(this).attr('data-id');

    ajax({
      type: 'GET',
      url: '/forklift_hours/new',
      data: qs.stringify({ forklift_id: forklift_id }),
      dataType: 'script',
    });
  });

  $(document).on('click', '.delete-forklift-hours-button', function(){
    let forklift_id = $(this).attr('data-id');

    if(!confirm('Remove this Hour Sheet?')) { return false; }

    ajax({
      type: 'DELETE',
      url: '/forklift_hours/' + forklift_id,
      data: qs.stringify({ forklift_id: forklift_id }),
      dataType: 'script',
    });
  });
});
