import $ from 'jquery';
import { debounce } from 'lodash';
import { ajax } from '@rails/ujs';
import qs from 'qs';
import LocalStorageHelper from './local_storage'
import AddressHelper from './address_helper'

const form = () => $('#memo-order-form');
const addItemForm = () => form().find('.add-item-form');
const itemsTable = () => form().find('.items-table');
const memo_address_loader = new AddressHelper({
  form_fields: {
    'name': 'memo_order_ship_to',
    'street': 'memo_order_street',
    'street-2': 'memo_order_street_2',
    'street-3': 'memo_order_street_3',
    'city': 'memo_order_city',
    'state': 'memo_order_state',
    'zip': 'memo_order_zip'
  },
  load_addresses_button: 'load-alternate-memo-address',
  load_button: 'load-address'
});
const storage = new LocalStorageHelper({
  select: form,
  data: [
    'select2-customer-container',
    'memo_order_ship_to',
    'memo_order_attn',
    'memo_order_street',
    'memo_order_street_2',
    'memo_order_street_3',
    'memo_order_city',
    'memo_order_state',
    'memo_order_zip',
    'memo_order_shipping_type',
    'memo_order_note',
    'memo_order_account_id',
    'memo_order_use_memo_accommodation_account',
    'tbody#memo-items',
    'sidemark-column',
    'note-column',
    'quantity-column'
  ],
  callback: updateItems,
  modalName: 'memoSavedModal',
  loadButton: 'load-form',
  clearButton: 'clear-form',
  drawerName: 'accountForm',
  formName: 'memoFormData',
  pageCheck: 'accounts',
  formPage: 'memo_orders/new',
  tableName: 'memo-items',
  loadAddress: memo_address_loader.set_account
});

function accountPicker() {
  form()
    .find('.accountpicker')
    .on('select2:select', e => {
      $.getJSON(`/accounts/${e.params.data.id}`, data => {
        $('#memo_order_ship_to').val(data.primary_address.name);
        ['street', 'street_2', 'street_3', 'city', 'state', 'zip'].forEach(
          field => {
            $(`#memo_order_${field}`).val(data.primary_address[field]);
          }
        );

        memo_address_loader.set_account(e.params.data.id);

        let { max_daily_memos } = data;
        if (max_daily_memos === -1) {
          max_daily_memos = 'none';
        }
        form()
          .find('.account-memos')
          .text(
            // prettier-ignore
            `Daily Limit: ${max_daily_memos} | Current Daily: ${data.current_daily_memos}`
          );
      });
    });
}

function useMemoAccommodationAccount() {
  const customer = $('.customer');
  const memoAccommodationAccount = $('.memo-accommodation-account');
  const name = 'memo_order[account_id]';

  function handleChange() {
    const checked = $(this).is(':checked');
    customer.toggleClass('d-none', checked);
    memoAccommodationAccount.toggleClass('d-none', !checked);
    if (checked) {
      customer.find('.accountpicker').removeAttr('name');
      memoAccommodationAccount.find('select').attr('name', name);
    } else {
      customer.find('.accountpicker').attr('name', name);
      memoAccommodationAccount.find('select').removeAttr('name');
    }
  }

  $('#memo_order_use_memo_accommodation_account').change(handleChange);

  handleChange();
}

function scanField() {
  const scan = form().find('#scan');

  scan.on('keypress', function (e) {
    if (e.which == 13) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  });

  scan.on(
    'keyup paste',
    debounce(() => {
      const query = scan.val();

      let product_id = false;
      if (query.toLowerCase()[0] === 'h') {
        product_id = query.split('=')[1];
      } else {
        product_id = query.slice(0, 7);
      }
      if (product_id.length < 7) {
        product_id = product_id.padStart(7, '0');
      }

      ajax({
        type: addItemForm().data('method'),
        url: addItemForm().data('url'),
        data: qs.stringify({ product_id }),
        dataType: 'script',
      });

      scan.val('');
    }, 500)
  );
}

function addItemButton() {
  form()
    .find('.add-button')
    .on('click', () => {
      const pattern = $('#pattern').val();
      if (pattern == null || pattern === '') {
        return;
      }
      const product_id = $('#product_id').val();
      if (product_id == null || product_id === '') {
        return;
      }

      ajax({
        type: addItemForm().data('method'),
        url: addItemForm().data('url'),
        data: qs.stringify({ pattern, product_id }),
        dataType: 'script',
        success: function() {
          $('.unavailable').prop("disabled", true);
          checkUnavailable();
          updateItems();
        }
      });
    });
}

function removeItemButton() {
  itemsTable().on('click', '.remove-button', function () {
    deletetItemCount(
      $(this)
        .parents("tr")
        .find("select")
    );

    $(this)
      .parents('tr')
      .remove();
    updateItems();
  });
}

function changePatternText(select, value){
  select.parent().siblings('.align-middle').css("color", value);
}

function checkUnavailable() {
  var flag = false;
  $('.color-column > .color-selector').each(function(index, value){
    if ($(value).val() === "-1" && $(value).children('.unavailable').length !== 0) {
      flag = true;
      changePatternText($(value), "#dc3545");
    } else {
      changePatternText($(value), "initial");
    }
  });
  if (flag) {
    toast('warning', 'Selected product has discontinued colors that have no stock.')
  }
}

function editColor() {
  itemsTable().on("click", "select", function () {
    $(this).data("lastValue", $(this).val());
    $('.unavailable').prop("disabled", true);
  });

  itemsTable().on("change", "select.color-selector", function () {
    updateItems();
    checkUnavailable();
    $('.unavailable').prop("disabled", true);
  });
}

function deletetItemCount(select) {
  // -1 value is "All Colors", subtract count of 1 for the "All Colors" option
  let decrement =
    $(select).val() === "-1" ? $(select).find("option").length - 1 : 1;

  $(".memo-item-count").html(
    parseInt($(".memo-item-count").html()) - decrement
  );
}

$(document).on('click', '#pl_search', function (e) {
  if ($('#customer').val() === null) {
    e.preventDefault();
    $(this).prop('checked', false);
    toast('info', 'Please select a customer to perform a Private Label search.');
  }
});

$(document).on('click', '#clearTable', function() {
  var confirm = window.confirm("Remove ALL memos from this order? This cannot be undone.");
  if (confirm) {
    $('#memo-items').empty();
    updateItems();
  }
});

function updateItems() {
  var counter = 0;
  $('.quantity').each(function (index) {
    var selected_color_value = $(this).parent().siblings('.color-column').find('.color-selector').val();
    if (selected_color_value === "-1") {
      var discontinuedItems = $(this).parent().siblings('.color-column').find('.color-selector > .unavailable').length || 0;
      counter += ((($(this).parent().siblings('.color-column').find('.color-selector > option').length) - (1 + discontinuedItems)) * ($(this)[0].value));
    } else {
      counter += parseInt($(this)[0].value);
    }
  });
  $('.memo-item-count').text(counter);
}

$(document).on("change", '.quantity', function() {
  updateItems();
});

export function init() {
  accountPicker();
  useMemoAccommodationAccount();
  scanField();
  updateItems();
  addItemButton();
  removeItemButton();
  editColor();
}

$(init);
