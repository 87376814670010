import m from 'mithril';

import TextWidget from './widgets/TextWidget';
import NumericWidget from './widgets/NumericWidget';
import DateWidget from './widgets/DateWidget';
import DropdownWidget from './widgets/DropdownWidget';

const Widget = {
  view({ attrs }) {
    let component;
    if (attrs.condition.type === 'text') {
      component = TextWidget;
    } else if (attrs.condition.type === 'numeric') {
      component = NumericWidget;
    } else if (attrs.condition.type === 'date') {
      component = DateWidget;
    } else if (attrs.condition.type === 'dropdown') {
      component = DropdownWidget;
    } else {
      throw new Error(`Invalid condition type ${attrs.condition.type}`);
    }
    const widget = m(component, {
      condition: attrs.condition,
      sources: attrs.sources,
    });

    return m('.d-flex.p-2.border-top.bg-white', [
      m('.row.mx-n1.w-100', [
        m('.col-4.px-1', [
          m(
            'select.custom-select.custom-select-sm',
            {
              onchange: e =>
                attrs.replaceCondition(
                  attrs.condition,
                  attrs.fields.find(f => f.name == e.target.value)
                ),
            },
            attrs.fields.map(({ name, title }) =>
              m(
                'option',
                { value: name, selected: name === attrs.condition.field },
                title
              )
            )
          ),
        ]),

        m('.col-8.px-1', widget),
      ]),

      m(
        'button.btn.btn-sm.btn-danger.px-3.ml-2[type=button]',
        { onclick: () => attrs.removeCondition(attrs.condition) },
        m('i.fa.fa-minus')
      ),
    ]);
  },
};

export default Widget;
