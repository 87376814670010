import $ from 'jquery';

import { connectDatatable } from '../filters';

$(() => {
  connectDatatable({
    rootSelector: '.account-filters',
    datatableSelector: '.datatable_accounts',
    builderAttrs: {
      defaultField: 'name',
    },
  });
});
