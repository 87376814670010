import $ from 'jquery';
import { ajax } from '@rails/ujs';
import qs from 'qs';
import { close_drawer } from './drawer';

$(() => {
  $('.search-trigger').on('change', function(){
    let params = {};

    if($('#contact_search').val() !== '' & $('#contact_search').val().length > 2){
      params.search = $('#contact_search').val();
    }

    if($('#exchange_only').is(':checked')){
      params.exchange_only = true;
    }

    params.sort_by = $('#sort_by').val().replace('_asc', '').replace('_desc', '');
    params.sort_direction = $('#sort_by').val().replace(params.sort_by + '_', '');

    // reset the page so we don't have to worry about being on a page that no longer exists
    params.page = 1

    params.offset = $('#offset').val();

    ajax({
      type: 'GET',
      url: '/contacts',
      data: qs.stringify(params),
      dataType: 'script',
    });
  });

  $('#contact-form').on('click', '.cancel-contact-edit', function(){
    $('#contact-form').addClass('d-none').html('');
    $('#contact-form-placeholder').removeClass('d-none');
    $('#contact-list').find('tr.active').removeClass('active');
  });

  $('.drawer').on('click', '.cancel-contact-edit', function(){
    close_drawer();
  });

  $('#contact-form').on('change', '#contact_first_name,#contact_last_name', function(){
    let new_name = ($('#contact_first_name').val() + ' ' + $('#contact_last_name').val()).trim();
    $('#contact_display_name').val(new_name);
  });
});
