import $ from 'jquery';
import { ajax } from '@rails/ujs';

function poll_for_notifications(){
  ajax({
    type: 'GET',
    url: '/notifications/poll',
    dataType: 'script',
  });

  setTimeout(poll_for_notifications, 2500)
}

$(() => {
  if (window.location.pathname !== "/login") {
    poll_for_notifications();
  }

  $(document).on("click", ".toggle-seen", function(e){
    if($(e.target).hasClass(".hide-alert")){ return false; }
    ajax({
      type: 'PATCH',
      url: $(this).attr('data-url'),
      dataType: 'script',
    });
  });

  $(document).on("click", ".hide-alert", function(e){
    e.stopPropagation();
    ajax({
      type: 'PATCH',
      url: $(this).attr('data-url'),
      dataType: 'script',
    });
  });
});
